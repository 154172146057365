<!--
 * @Description: 用户端-首页-支付信息-支付详情 selfPayRecordInfo
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 12:57:26
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="支付详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <!-- 订单申诉 -->
      <moveBox right="12px"
               top="76px"
               endRight="12px">
        <van-button type="warning"
                    style="height:100px;width:40px;"
                    @click="toOrderComplain">订单申诉</van-button>
      </moveBox>
      <van-row class="contain">
        <van-row class="top">
          <van-row class="picBox">
            <van-image class="pic"
                       :src="require('@/assets/wxPayRecord/tingchechang.png')" />
          </van-row>
          <van-row class="title">重庆市渝北区公共停车场</van-row>
          <van-row class="price">{{orderDetail.receivedMoneyAmount}}元</van-row>
        </van-row>
        <van-row class="info">
          <van-row class="infoLine">
            <van-col class="infoTitle">收费规则</van-col>
            <van-col class="infoDetail"
                     @click="toRegulation">
              {{orderDetail.billingRuleDefName}}
              <van-image class="toregulation"
                         :src="require('@/assets/wxPayRecord/toregulation.png')" />
            </van-col>
          </van-row>
          <van-row class="infoLine">
            <van-col class="infoTitle">车牌号</van-col>
            <van-col class="infoDetail">{{orderDetail.plateNumber}}</van-col>
          </van-row>
          <van-row class="infoLine"
                   v-show="parkSpaceNumberShow">
            <van-col class="infoTitle">泊位号</van-col>
            <van-col class="infoDetail">{{orderDetail.parkSpaceNumber}}</van-col>
          </van-row>
          <van-row class="infoLine">
            <van-col class="infoTitle">停车时长</van-col>
            <van-col class="infoDetail">{{orderDetail.parkDuration}}</van-col>
          </van-row>
          <van-row class="infoLine">
            <van-col class="infoTitle">订单创建时间</van-col>
            <van-col class="infoDetail">{{orderDetail.orderCreateTime}}</van-col>
          </van-row>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import formatDuration from '@/utils/duration'
import moveBox from '@/components/moveBox'
import parkStopTime from '@/utils/parkStopTime'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: { moveBox },
  data () {
    // 这里存放数据
    return {
      orderSequence: '',
      orderDetail: {}, // 从支付详情订单拿到的对象
      container: null // 订单申诉
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.orderSequence = this.$route.query.orderSequence
    this.initialization()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 初始化页面
    initialization () {
      const info = {
        orderSequence: this.orderSequence
      }
      this.$payRecord.getOrderByPayment(info).then(res => {
        res.resultEntity.receivedMoneyAmount =
          Number(Number(res.resultEntity.receivedMoneyAmount) / 100).toFixed(2)
        // res.resultEntity.parkDuration = formatDuration(res.resultEntity.parkDuration)
        res.resultEntity.parkDuration = parkStopTime(res.resultEntity.entranceTime, res.resultEntity.leaveTime)
        this.orderDetail = res.resultEntity
        console.log(this.orderDetail.parkSpaceNumber)
        if (this.orderDetail.parkSpaceNumber !== '' && this.orderDetail.parkSpaceNumber !== undefined) {
          this.parkSpaceNumberShow = true
        } else {
          this.parkSpaceNumberShow = false
        }
      })
    },
    // 跳转订单申诉
    toOrderComplain () {
      this.$router.push({
        name: 'selfOrderComplain',
        query: {
          orderSequence: this.orderDetail.orderSequence,
          moneyAmount: this.orderDetail.receivableMoneyAmount,
          moneyPay: this.orderDetail.receivedMoneyAmount,
          paymentSequence: this.$route.query.paymentSequence
        }
      })
    },
    // 跳转计费规则
    toRegulation () {
      this.$router.push({
        name: 'selfBillRuleDetail',
        query: {
          parkId: this.orderDetail.parkId
        }
      })
    },
    // 返回上一页
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    padding: 12px;
    .contain {
      height: 349px;
      background: #fff;
      border-radius: 5px;
      padding: 20px 12px;
      .top {
        width: 100%;
        .picBox {
          display: flex;
          justify-content: center;
          .pic {
            width: 41px;
            height: 40px;
          }
        }
        .title {
          display: flex;
          justify-content: center;
          margin-top: 13px;
          font-size: 17px;
          color: #333333;
          font-weight: 400;
        }
        .price {
          display: flex;
          justify-content: center;
          margin: 19px 0 20px 0;
          color: #333333;
          font-weight: 700;
          font-size: 23px;
        }
      }
      .info {
        .infoLine {
          margin: 20px 0px 0px;
          .infoTitle {
            color: #909090;
            font-size: 15px;
            font-weight: 400;
          }
          .infoDetail {
            float: right;
            color: #333333;
            font-size: 15px;
            font-weight: 400;
            .toregulation {
              width: 5px;
              height: 9px;
            }
          }
        }
      }
    }
  }
}
</style>
