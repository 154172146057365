<!--
 * @Description: 移动盒子
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-01 20:21:50
 * @LastEditors: LinFeng
-->
<template>
  <div id='moveBox'
       ref="moveBox"
       :style="'position: fixed;left:'+sleft+';right:'+sright+';top:'+stop+';bottom:'+sbottom+';z-index: 100;'"
       @touchstart="touchstart"
       @touchend="touchend"
       @touchmove="touchmove">
    <slot id="box"></slot>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  /**
   * 起始位置: left right top bottom,
   * 结束位置: endLeft endRight endTop endBottom,
   * ps: 左和右互相冲突, 上和下互相冲突
   */
  props: {
    left: {
      type: String,
      default: ''
    },
    right: {
      type: String,
      default: ''
    },
    top: {
      type: String,
      default: ''
    },
    bottom: {
      type: String,
      default: ''
    },
    endLeft: {
      type: String,
      default: ''
    },
    endRight: {
      type: String,
      default: ''
    },
    endTop: {
      type: String,
      default: ''
    },
    endBottom: {
      type: String,
      default: ''
    }
  },
  data () {
    // 这里存放数据
    return {
      sleft: '',
      sright: '',
      stop: '',
      sbottom: '',

      eleft: '',
      eright: '',
      etop: '',
      ebottom: '',

      clientWidth: 0,
      clientHeight: 0,
      width: 0,
      height: 0
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.sleft = this.left
    this.sright = this.right
    this.stop = this.top
    this.sbottom = this.bottom
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.clientWidth = document.body.clientWidth
    this.clientHeight = document.body.clientHeight
    this.width = document.getElementById('moveBox').children[0].offsetWidth
    this.height = document.getElementById('moveBox').children[0].offsetHeight
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    touchstart (ev) {
      // console.log('按下', ev)
    },
    touchend (ev) {
      if (this.endLeft) {
        this.sleft = this.endLeft
        this.sright = ''
      }
      if (this.endRight) {
        this.sleft = ''
        this.sright = this.endRight
      }
      if (this.endTop) {
        this.stop = this.endTop
        this.sbottom = ''
      }
      if (this.endBottom) {
        this.sbottom = this.endBottom
        this.stop = ''
      }
    },
    touchmove (ev) {
      const left = ev.changedTouches[0].clientX
      const top = ev.changedTouches[0].clientY
      if (left + this.width / 2 < this.clientWidth && left - this.width / 2 > 0) {
        this.sleft = left - this.width / 2 + 'px'
      }
      if (top + this.height / 2 < this.clientHeight && top - this.height / 2 > 0) {
        this.stop = top - this.height / 2 + 'px'
      }
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
#moveBox {
}
</style>
